<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/pay.png" alt="" width="28">
        <h2>支付管理</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <strong>共计收取金额：{{ totalPay | filterMoney }} 元</strong>
        <el-input clearable v-model="search" placeholder="请输入公司名称" suffix-icon="el-icon-search"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 320px)">
        <el-table-column prop="companyCode" label="企业代码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="companyTax" label="公司税号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="支付金额(元)" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.payFee | filterMoney }}
          </template>
        </el-table-column>
        <el-table-column label="支付时间" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.payTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="支付人" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.username ? scope.row.username.split('.')[1] : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="payType" label="支付方式" show-overflow-tooltip>
          <template slot-scope="scope">
            <span :class=" scope.row.payType =='bank' ? 'colorStyle' : 'wxPay'">
              {{ scope.row.payType == 'bank' ? '银行转账' : '微信支付' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="支付凭证" min-width="100" class-name="colorStyle">
          <template slot-scope="scope">
            <template v-if="scope.row.payVoucher&&scope.row.payVoucher.length>0">
              <img v-for="item in scope.row.payVoucher" :src="item" alt="" height="50px">
            </template>
          </template>
        </el-table-column>
        <el-table-column label="发票凭证" class-name="colorStyle" min-width="100">
          <template slot-scope="scope">
            <template v-if="scope.row.invoice&&scope.row.invoice.length>0">
              <img v-for="item in scope.row.invoice" :src="item" alt="" height="50px">
            </template>
          </template>
        </el-table-column>
        <el-table-column label="支付状态" width="120">
          <template slot-scope="scope">
            <el-button size="small" v-if="scope.row.payStatus==1" class="finished">已充值</el-button>
            <el-button size="small" v-else class="approval">待充值</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="topUp(scope.row.payFee,scope.row.id)"
                       v-if="scope.row.payType == 'bank' && scope.row.payStatus==0">充值
            </el-button>
            <el-button type="text" size="small" v-if="scope.row.payStatus==1" @click="upload(scope.row.id)">
              上传发票凭证
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <!--    上传发票凭证-->
    <el-dialog  :close-on-click-modal="false"
        title="上传发票凭证"
        class="flex dialogForm "
        top="0"
        :visible.sync="dialogFormVisible"
        width="450px">
      <el-upload
          class="upload-demo"
          ref="upload"
          multiple
          :action="upLoadUrl"
          :headers="headers"
          :on-preview="handlePreview"
          :before-remove="beforeRemove"
          :on-success="handleAvatarSuccess"
          :file-list="fileList"
          :before-upload="beforeAvatarUpload"
          list-type="picture"
          accept=".jpg,.jpeg,.png">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitUpload">提交</el-button>
      </div>
    </el-dialog>
    <!--    发票大图-->
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <!--充值金额确认-->
    <el-dialog  :close-on-click-modal="false"
        title="确认支付金额"
        class="flex dialogForm "
        top="0"
        :visible.sync="dialogPay"
        width="450px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":"
               label-width="90px">
        <el-input v-model="formAction.orderFee" placeholder="请输入金额" min="0" type="number">
          <span slot="suffix">元</span>
        </el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitPay">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "companyPay",
  data() {
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      totalPay: null,
      dialogFormVisible: false,
      upLoadUrl: this.$local.baseURL() + 'files/file',
      fileList: [],//文件
      dialogImageUrl: '',//大图
      dialogVisible: false,
      uploadForm: {
        uploadId: null
      },
      headers: {
        "Authentication": this.$store.state.account.token
      },

      dialogPay: false,
      formAction: {
        id: null,
        orderFee: null
      }
    }
  },
  mounted() {
    this.getData()
    this.getTotal()
  },
  filters: {
    filterMoney(num) {
      return (num / 100).toFixed(2)
    }
  },
  methods: {
    getData() {
      this.$postJson('/order/pay-order/admin/page',
          {
            size: this.page.size,
            page: this.page.current,
            key: this.search
          }).then(res => {
        if (res.data.success) {
          // 时间处理
          res.data.data.records.forEach(item => {
            if (item.payTime) {
              let a = item.payTime
              item.payTime = a.substring(0, 4) + '-' + a.substring(4, 6) + '-' + a.substring(6, 8)
            } else {
              item.payTime = '-'
            }
            if (item.payVoucher) {
              item.payVoucher = item.payVoucher.split(",")
            }
            if (item.invoice) {
              item.invoice = item.invoice.split(",")
            }
          })

          this.tableData = res.data.data.records;
          this.page.current = res.data.data.current;
          this.page.total = res.data.data.total;
          this.page.size = res.data.data.size;
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    // 确认是否充值成功
    topUp(money, id) {
      this.dialogPay = true
      this.formAction.orderFee = parseFloat((money / 100).toFixed(2));
      this.formAction.id = id
    },
    submitPay() {
      this.formAction.orderFee = JSON.parse(this.formAction.orderFee) * 100
      this.$postJson("/order/pay-order/admin/confirm", this.formAction).then(res => {
        if (res.data.success) {
          this.$message.success("确认成功");
          this.getData();
          this.dialogPay = false
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    // 获取总共收取金额
    getTotal() {
      this.$postJson('/order/pay-order/admin/total/income').then(res => {
        if (res.data.success) {
          this.totalPay = res.data.data
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 打开上传窗口
    upload(id) {
      this.dialogFormVisible = true
      this.fileList = []
      this.uploadForm.uploadId = id
    },
    beforeAvatarUpload(file) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
      }
    },
    // 点击打开看大图
    handlePreview(file) {
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    },
    // 删除
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleAvatarSuccess(response, file) {
      let obj = {
        name: response.name,
        url: response.url
      };
      this.fileList.push(obj);
    },
    // 提交
    submitUpload() {
      let name = this.fileList.map(o => o.url)
      this.invoice = name.join(",");

      // this.$refs.upload.submit();
      let params = {
        id: this.uploadForm.uploadId,
        invoice: this.invoice
      }
      this.$postJson('/order/pay-order/admin/invoice', params).then((res) => {
        if (res.data.success) {
          this.$message.success("上传成功")
          this.dialogFormVisible = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
